import { createRouter, RouteRecordRaw, createWebHistory } from 'vue-router'
import { dashboardLawyerRoutes } from './dashboardLawyerRoutes'
import store from '@/store/index'
import { changeMetas } from '@/services/setMetas'
import { ref } from 'vue'

const redirectCount = ref(0)

const routes: Array<RouteRecordRaw> = [
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/pages/RegisterView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/pages/LoginView.vue')
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: () => import('../views/dashboard-lawyer/PricingView.vue')
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/pages/HomePage.vue')
  },
  {
    path: '/about',
    name: 'about-page',
    component: () => import('../views/pages/AboutPage.vue')
  },
  {
    path: '/contact',
    name: 'contact-page',
    component: () => import('../views/pages/ContactPage.vue')
  },
  {
    path: '/faq',
    name: 'faq-page',
    component: () => import('../views/pages/FaqPage.vue')
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import('../views/checkout/CheckoutView.vue')
  },
  {
    path: '/signature-request/download',
    name: 'signature-request-download',
    component: () =>
      import('../views/pages/CustomerSignatureRequestDownloadView.vue')
  },
  {
    path: '/invoice/download',
    name: 'invoice-download',
    component: () => import('../views/pages/CustomerInvoiceDownloadView.vue')
  },
  {
    path: '/lawyer-update-password',
    name: 'lawyer-update-password',
    component: () => import('../views/pages/LawyerUpdatePasswordView.vue')
  },
  ...dashboardLawyerRoutes
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// Fonction pour vérifier l'accès au dashboard
async function checkDashboardAccess(to: any, from: any, next: any) {
  const isAuthenticated = localStorage.getItem('access_token')

  if (redirectCount.value > 30) {
    console.error('Too many redirections')
    return
  }

  redirectCount.value++

  store.dispatch('setOverflowHidden', {
    to: to.fullPath,
    width: window.innerWidth
  })

  changeMetas(to.path)

  if (
    (to.path.includes('/dashboard/lawyer') || !to.path.includes('/pricing')) &&
    isAuthenticated &&
    !to.path.includes('/checkout')
  ) {
    if (
      to.path.includes('/dashboard/lawyer/success-checkout') &&
      to.query.s_id
    ) {
      // Success checkout
      next()
    } else {
      //isAuthenticated

      const lawyerData = await store.dispatch('getLawyerData')
      // console.log('lawyerData : ', lawyerData)

      if (
        lawyerData === 'api.error.stripe.checkout.not_found' &&
        !to.path.includes('/dashboard/pricing')
      ) {
        next('/pricing')
      } else if (lawyerData === 'api.error.stripe.checkout.not_found') {
        next()
      }

      if (typeof lawyerData === 'object') {
        if (
          !to.path.includes('/dashboard/pricing') &&
          !lawyerData.society.stripe.isSubscribed
        ) {
          next('/pricing')

          // Check if user is finished
        } else if (
          !to.path.includes('/dashboard/lawyer/account-validation') &&
          (!lawyerData.stripe.isFinished ||
            !lawyerData.society.stripe.hasConnectedAccount)
        ) {
          console.log('Check if user is finished')
          next('/dashboard/lawyer/account-validation')

          // Check if user has connected account and have finished it
        } else if (
          to.path.includes('/dashboard/lawyer/account-validation') &&
          lawyerData.society.stripe.hasConnectedAccount &&
          lawyerData.stripe.missingKeys.length === 0
        ) {
          console.log(
            'Check if user has connected account and have finished it'
          )
          next('/dashboard/lawyer/home')

          // Check if login => redirect to dashboard
        } else if (to.path.includes('/login')) {
          next('dashboard/lawyer/home')

          // Check if user is already in dashboard
        } else {
          next()
        }
      } else {
        next()
      }
    }

    // Not authenticated
  } else if (to.path.includes('/dashboard/lawyer') && !isAuthenticated) {
    next('/login')
  } else {
    next()
  }
}

function goToTop() {
  window.scrollTo(0, 0)
}

router.beforeEach((to, from, next) => {
  checkDashboardAccess(to, from, next)
  next()
  goToTop()

  store.commit('SET_FULL_PATH', to.fullPath)
  store.commit('SET_SCREEN_WIDTH', window.innerWidth)
  store.commit(
    'SET_IS_LOGGED',
    localStorage.getItem('access_token') ? true : false
  )
  store.commit('SET_IS_DASHBOARD', to.path.includes('/dashboard'))
  store.commit('SET_IS_CHECKOUT', to.path.includes('/checkout'))
})

export default router
